<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="column is-5 is-offset-4">
          <form>
            <div class="box">
              <b-field>
                <b-input
                  v-model="oldPassword"
                  type="password"
                  size="is-large"
                  placeholder="Sandi lama"
                  iconPack="fas"
                  icon="lock"
                  password-reveal
                ></b-input>
              </b-field>
              <b-field>
                <b-input
                  v-model="newPassword"
                  type="password"
                  size="is-large"
                  placeholder="Sandi baru"
                  iconPack="fas"
                  icon="lock"
                  password-reveal
                ></b-input>
              </b-field>
              <b-button
                type="is-primary"
                size="is-large"
                expanded
                @click.stop.prevent="changePassword"
              >Ganti Sandi</b-button>
              <router-link
                tag="button"
                class="button is-fullwidth is-primary is-large"
                :to="{ name: 'dashboard' }"
              >Batal</router-link>
            </div>
          </form>
        </div>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";

const apiURL = APP_CONFIG.baseAPIURL + "/anggota/ubah-sandi/"; // perlu diperbaiki url-nya lebih cocok ke account

const fieldMessageMap = {
  old_password: "Sandi lama: ",
  new_password: "Sandi baru: ",
  non_field_errors: "Sandi baru: "
};

export default {
  name: "ChangePassword",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      loading: false
    };
  },
  methods: {
    changePassword() {
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword
      };
      this.loading = true;
      axios
        .patch(apiURL, data)
        .then(() => {
          this.$buefy.toast.open("Sandi berhasil diubah.");
          this.$router.push({ name: "dashboard" });
        })
        .catch(error => {
          if (error.response.status === 400) {
            const errDict = error.response.data;
            const field = Object.keys(errDict)[0];
            // get first error message
            const errMsg = fieldMessageMap[field] + errDict[field][0];
            this.$buefy.snackbar.open({
              message: errMsg,
              position: "is-top",
              type: "is-warning",
              actionText: "OK"
            });
            // for (const [key, valArr] of Object.entries(error.response.data)) {
            //   let errMsg = fieldMessageMap[key] + valArr.join(" ");
            //   this.$buefy.snackbar.open({
            //     message: errMsg,
            //     position: "is-top",
            //     type: "is-warning",
            //     actionText: "OK"
            //   });
            // }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  font-size: 14px;
  font-weight: 300;
}

button {
  margin: 0.25rem;
}

::v-deep .is-right {
  color: $ppds-blue !important;
}
</style>
